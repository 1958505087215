export enum SalesRoute {
  ordersReport = 'orders-report',
  bookingReport = 'booking-report',
  shifts = 'shifts',
}

export enum SalesRouteName {
  ordersReport = 'Заказы',
  bookingReport = 'Бронирования',
  shifts = 'Кассовые смены',
}

export enum SalesRouteParam {
  orderId = 'idOrder',
  shiftId = 'idShift',
}

export const SALES_ITEM_ORDERS = {
  title: SalesRouteName.ordersReport,
  route: SalesRoute.ordersReport,
  routes: [
    {
      title: '',
      route: SalesRouteParam.orderId,
      keepUrlParams: true,
    },
  ],
};

export const SALES_ITEM_BOOKING = {
  title: SalesRouteName.bookingReport,
  route: SalesRoute.bookingReport,
};

export const SALES_ITEM_SHIFTS = {
  title: SalesRouteName.shifts,
  route: SalesRoute.shifts,
  routes: [
    {
      title: '',
      route: SalesRouteParam.shiftId,
      keepUrlParams: true,
    },
  ],
};

export const SALES_CHILD_ITEMS = [SALES_ITEM_ORDERS, SALES_ITEM_BOOKING, SALES_ITEM_SHIFTS];
