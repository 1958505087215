import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CachedCatalogTreeService } from '@app/modules/catalog/services/ccached-catalog/cached-catalog-tree.service';
import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { SearchAutocompleteServiceFactory } from '@app/shared/service/autocomplete/search-autocomplete.service';
import { TreeDataService } from '@app/shared/service/tree-data/tree-data.service';
import { MAX_CHARACTERS } from '@constants';
import { ValidationErrorsService } from '@core/service';
import { MenuService } from '@services/catalog';
import { StoresService } from '@services/settings';
import { SearchAutocompleteService, TreeDataAutocompleteService } from '@services/shared';
import { MenuRc, Store } from '@typings';

@Component({
  selector: 'nm-menu-form-dialog',
  templateUrl: './menu-form-dialog.component.html',
  styleUrls: ['./menu-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuFormComponent implements OnInit {
  MAX_CHARACTERS = MAX_CHARACTERS;

  searchStoresService: SearchAutocompleteService<Store>;
  treeDataService: TreeDataService<AutocompleteOption>;

  autocompleteTreeService = new TreeDataAutocompleteService();

  get menu(): MenuRc | undefined {
    return this.data.menu;
  }

  get menuName(): string | undefined {
    return this.data.menuName;
  }

  get isNew(): boolean {
    return !this.menu;
  }

  get btnTitle(): string {
    return this.isNew ? 'Создать' : 'Сохранить';
  }

  get title() {
    return this.isNew ? 'Новое меню' : this.menu?.name;
  }

  get controls() {
    return this.menuService.form.controls;
  }

  get name() {
    return this.controls.name;
  }

  get stores() {
    return this.controls.stores;
  }

  get structure() {
    return this.controls.structure;
  }
  get useStructure() {
    return this.controls.useStructure;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { menu: MenuRc | undefined; menuName?: string },
    public menuService: MenuService,
    public validationErrorsService: ValidationErrorsService,
    private storesService: StoresService,
    private cachedCatalogTreeService: CachedCatalogTreeService,
  ) {
    this.menuService.isLoading$.next(false);
    if (this.menu) {
      this.menuService.initUpdateMenuForm(this.menu);
    } else {
      this.menuService.initForm(this.menu);
    }

    if (this.menuName) {
      this.name.setValue(this.menuName);
    }

    this.searchStoresService = SearchAutocompleteServiceFactory.getService(this.#searchStoresFn);
  }

  ngOnInit(): void {
    this.treeDataService = this.cachedCatalogTreeService.initSections();
    this.autocompleteTreeService.init(this.treeDataService);
    this.searchStoresService.searchFn('');
  }

  #searchStoresFn = (searchText: string): Observable<AutocompleteOption<Store>[]> => {
    return this.storesService.getStoresForSearch(searchText || '').pipe(
      map((stores) =>
        stores.map((store) => ({
          label: store.name,
          type: 'item',
          id: store.id,
          data: store,
        })),
      ),
    );
  };

  cancel() {
    this.menuService.closeMenuForm(false);
  }

  change(event: MatCheckboxChange) {
    this.useStructure!.setValue(event.checked);
  }

  confirm() {
    this.validationErrorsService.markFormControls(this.menuService.form);

    if (this.menuService.form.valid) {
      this.menuService.isLoading$.next(true);
      if (this.menu) {
        this.menuService.editMenu(this.menu);
      } else {
        this.menuService.createMenu();
      }
    }
  }

  deleteStoreChip(storeIds: string[], options: AutocompleteOption<Store>[]): void {
    const selectedOptions = options.filter((option) => storeIds.includes(option.id));

    this.stores.setValue(selectedOptions);
  }

  controlErrorHint(control: AbstractControl, errorCode: string): string {
    return this.validationErrorsService.validateField(control) || control.getError(errorCode);
  }
}
