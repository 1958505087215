import { ConnectedPosition, OverlayRef } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import { FilterPanelService } from '@app/shared/service/filter-panel/filter-panel.service';
import { BreakpointObserverService } from '@services/core';

import { DropdownMenuComponent } from '../dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'nm-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPanelComponent implements AfterViewInit, OnDestroy {
  @ViewChild('dropdown', { read: DropdownMenuComponent }) dropdown: DropdownMenuComponent;

  overlayRef: OverlayRef;
  destroy$ = new Subject<void>();
  currentView$ = new BehaviorSubject<TemplateRef<unknown> | null>(null);
  isMobile = this.bos.getIsMobile();
  @Input() positions: ConnectedPosition[] = [
    { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
    { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
    { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
  ];

  @Input() initView: TemplateRef<unknown>;

  @Output() backdropClick = new EventEmitter<void>();
  @Output() menuOpened = new EventEmitter<void>();
  @Output() menuClosed = new EventEmitter<void>();

  constructor(private filterPanelService: FilterPanelService, private bos: BreakpointObserverService) {}

  ngAfterViewInit(): void {
    this.filterPanelService.currentView$.pipe(takeUntil(this.destroy$)).subscribe((view) => {
      this.dropdown.setView(view);
    });

    this.filterPanelService.isPanelOpened$.pipe(takeUntil(this.destroy$)).subscribe((isOpen) => {
      if (isOpen) {
        this.dropdown.openOverlay();
      } else {
        this.dropdown.close();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
