import { PortalModule } from '@angular/cdk/portal';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { IMaskModule } from 'angular-imask';

import { BackButtonModule, ButtonModule, IconModule, MyButtonModule, NotificationModule } from '@nomia/nomia-components-lib';

import { ApolloModule } from '@api';
import { ConfigService } from '@app/config.service';
import { AuthModule } from '@app/modules/auth/auth.module';
import { DialogModule } from '@app/shared/component/dialog/dialog.module';
import { NotificationsModule } from '@app/shared/component/notifications/notifications.module';
import { IntervalService } from '@app/shared/service/interval.service';
import { SharedModule } from '@app/shared/shared.module';
import { ExportInterceptor, OrganizationInterceptor } from '@core/interceptor';
import { ConnectCheckService } from '@core/service/connect-check.service';
import {
  AccessGuard,
  AccountGuard,
  AuthGuard,
  CatalogSettingsGuard,
  FeatureProfileGuard,
  InvitationGuard,
  OrganizationGuard,
  SetCatalogGuard,
} from '@guards';
import { TraceService } from '@sentry/angular';

import { LayoutModule } from './layout/layout.module';
import { LoggerService } from './shared/service/logger/logger.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SentryErrorHandler } from './sentry.error-handler';

@NgModule({
  declarations: [AppComponent],
  imports: [
    DialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    HttpClientModule,
    IMaskModule,
    ApolloModule,
    GoogleTagManagerModule.forRoot({ id: 'GTM-5KCRCTG' }),
    IconModule,
    BackButtonModule,
    ButtonModule,
    NotificationModule,
    MyButtonModule,
    PortalModule,
    LayoutModule,
    NotificationsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => configService.config$,
      deps: [ConfigService, LoggerService],
      multi: true,
    },
    ApolloModule,
    DatePipe, // FIXME: move into SharedPipes
    FeatureProfileGuard,
    AuthGuard,
    AccountGuard,
    OrganizationGuard,
    AccessGuard,
    InvitationGuard,
    CatalogSettingsGuard,
    SetCatalogGuard,
    ConnectCheckService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrganizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExportInterceptor,
      multi: true,
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    // remove after Marketing update
    {
      provide: IntervalService,
      useClass: IntervalService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
