import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { OrderStateStrict, OrderStateString, PosOrderStatusStrict, PosOrderStatusString } from '@typings';

export const ORDER_STATE: OrderStateStrict = {
  PREORDER: 'PREORDER',
  NEW: 'NEW',
  OPEN: 'OPEN',
  ACCEPTED_BY_RESTAURANT: 'ACCEPTED_BY_RESTAURANT',
  IN_PROGRESS: 'IN_PROGRESS',
  READY: 'READY',
  TAKEN_BY_COURIER: 'TAKEN_BY_COURIER',
  CLOSED: 'CLOSED',
  CANCELED: 'CANCELED',
};

export const ORDER_STATE_STRING: OrderStateString = {
  NEW: 'Новый',
  CLOSED: 'Закрыт',
  CANCELED: 'Отменен',
  ACCEPTED_BY_RESTAURANT: 'Принято',
  IN_PROGRESS: 'Готовится',
  OPEN: 'Открыт',
  PREORDER: 'Предзаказ',
  READY: 'Готов',
  TAKEN_BY_COURIER: 'Передан курьеру',
};

export const POS_ORDER_STATE: PosOrderStatusStrict = {
  PREORDER: 'PREORDER',
  NEW: 'NEW',
  OPEN: 'OPEN',
  ACCEPTED_BY_RESTAURANT: 'ACCEPTED_BY_RESTAURANT',
  IN_PROGRESS: 'IN_PROGRESS',
  READY: 'READY',
  TAKEN_BY_COURIER: 'TAKEN_BY_COURIER',
  CLOSED: 'CLOSED',
  CANCELED: 'CANCELED',
};

export const POS_ORDER_STATE_STRING: PosOrderStatusString = {
  NEW: 'Новый',
  CLOSED: 'Закрыт',
  CANCELED: 'Отменен',
  ACCEPTED_BY_RESTAURANT: 'Принято',
  IN_PROGRESS: 'Готовится',
  OPEN: 'Открыт',
  PREORDER: 'Предзаказ',
  READY: 'Готов',
  TAKEN_BY_COURIER: 'Передан курьеру',
};

export const ORDER_STATE_MENU_ITEMS: MenuItem[] = Object.keys(ORDER_STATE).map((orderState) => ({
  id: orderState,
  type: 'item',
  label: ORDER_STATE_STRING[orderState],
}));
