import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ParamsService } from '@core/service/params.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  search$ = new BehaviorSubject<string>(this.paramsService.getParamByName('search'));
  openSearch$: BehaviorSubject<boolean>;

  constructor(private paramsService: ParamsService) {}

  search(search: string) {
    this.paramsService.setParams([{ name: 'search', value: search }]);
    this.search$.next(search);
  }

  resetSearch() {
    this.search$.next('');
    this.openSearch$.next(false);
  }
}
