import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { SearchService } from '@app/shared/service/search/search.service';
import { ActionComponent } from '@typings';

import { ActionMenuItem, ActionsSettings } from './actions.model';

@Component({
  selector: 'nm-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsComponent implements ActionComponent, OnInit {
  [key: string]: unknown;
  items = [];

  @Input() isMobile: Observable<boolean>;
  @Input() isTablet: Observable<boolean>;
  @Input() isSearch: boolean;
  @Input() settings: ActionsSettings;

  items$: Observable<ActionMenuItem[]>;
  isMenuDisabled$: Observable<boolean>;

  constructor(private searchService: SearchService) {}

  ngOnInit(): void {
    this.items$ = this.isMobile
      ? this.isMobile.pipe(
          map((isMobile) => {
            return this.getItems(isMobile);
          }),
        )
      : of(this.getItems(false));

    this.isMenuDisabled$ = this.items$.pipe(
      map((items) => {
        if (!this.settings.disableMenuTriggerIfAllMenuItemsDisabled) {
          return false;
        }

        return items.filter((i) => i.type === undefined || i.type === 'item').every((i) => !!i.disabled);
      }),
    );
  }

  private getItems(isMobile: boolean): ActionMenuItem[] {
    const menuItems = this.settings?.items ? [...this.settings.items] : [];
    if (isMobile && this.settings?.buttonAction) {
      if (!!menuItems.length) {
        menuItems.unshift({
          label: 'divider',
          type: 'divider',
        });
      }
      menuItems.unshift(this.settings.buttonAction);
    }
    return menuItems.map((i) => {
      if (!i.type) {
        i.type = 'item';
      }
      return i;
    });
  }

  setSearch() {
    this.searchService.openSearch$.next(true);
  }
}
