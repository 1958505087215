import { YandexConfigurationStatusStrict, YandexConfigurationStatusString } from '@app/typings/application';

export const APPLICATION_NAMES: Record<string, string> = {
  YANDEX_EDA: 'Яндекс.Еда',
  GUEST_TELEGRAM_BOT: 'Telegram консьерж-бот',
  ADMIN_TELEGRAM_BOT: 'Telegram бот для бизнеса',
};

export const APPLICATION_IMAGES: Record<string, string> = {
  YANDEX_EDA: '../assets/svg-icons/yandex_eats.svg',
  GUEST_TELEGRAM_BOT: '../assets/svg-icons/telegram-icon.svg',
  ADMIN_TELEGRAM_BOT: '../assets/svg-icons/telegram-icon.svg',
};

export const CONFIGURATION_STATUS: YandexConfigurationStatusStrict = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  CONFIGURING: 'CONFIGURING',
};

export const CONFIGURATION_STATUS_STRING: YandexConfigurationStatusString = {
  ACTIVE: 'Подключен',
  DISABLED: 'Не подключен',
  CONFIGURING: 'Не подключен',
};

export const getApplicationName = (application: string): string => APPLICATION_NAMES[application];

export const getApplicationImage = (application: string): string => APPLICATION_IMAGES[application];
