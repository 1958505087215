import { ChangeDetectionStrategy, Component, ComponentRef, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, take } from 'rxjs';

import { NavigationDrawerComponent } from '@app/shared/component/navigation-drawer/navigation-drawer.component';
import { TableSidebarLayoutService } from '@app/shared/component/table-sidenav-layout/service/table-sidebar-layout.service';
import { SearchService } from '@app/shared/service/search/search.service';
import { NAV_DRAWER_ITEMS } from '@constants';
import { BreakpointObserverService } from '@core/service/breakpoint-observer.service';
import { SessionStorage } from '@services/api';
import { AccountStorage } from '@services/core';
import { FeatureEnableService, SidenavService, SubscriptionAlertsService } from '@services/shared';
import { ActionComponent, BannerType, NavDrawerItem, SidenavStateType } from '@typings';

import { TopBarActionsDirective } from './top-bar-actions.directive';

@Component({
  selector: 'nm-sidenav-layout',
  templateUrl: './sidenav-layout.component.html',
  styleUrls: ['./sidenav-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavLayoutComponent implements OnInit {
  @ViewChild(NavigationDrawerComponent) navDrawer: NavigationDrawerComponent;
  @ViewChild(TopBarActionsDirective, { static: true }) actionsHost!: TopBarActionsDirective;
  tableSidenavLoading = false;

  get layoutClasses(): string[] {
    const classes = ['nm-sidenav-layout'];
    const layoutRef = this.layoutRef.nativeElement;
    const detailsRef = layoutRef.querySelector('.layout-details > router-outlet ~ * ');

    if (this.bos.getIsMobile()) {
      classes.push('mobile');
    }

    if (detailsRef !== null) {
      classes.push('details');
    }

    return classes;
  }

  get subscriptionAlertTitle(): string {
    return this.subscriptionAlertsService.geSubscriptionAlertTitle();
  }

  get subscriptionAlertType(): BannerType {
    return this.subscriptionAlertsService.getSubscriptionAlertType();
  }

  get showSubscriptionAlert(): boolean {
    return this.subscriptionAlertsService.showSubscriptionAlert();
  }

  navItems$ = new BehaviorSubject<NavDrawerItem[]>([]);

  dropdownState$ = new BehaviorSubject<Boolean>(false);
  isMouseLeft$ = new BehaviorSubject<Boolean>(false);

  constructor(
    public bos: BreakpointObserverService,
    public sessionStorage: SessionStorage,
    public accountStorage: AccountStorage,
    public sidenavService: SidenavService,
    private features: FeatureEnableService,
    private layoutRef: ElementRef,
    public subscriptionAlertsService: SubscriptionAlertsService,
    public tableSidebarService: TableSidebarLayoutService,
    public searchService: SearchService,
  ) {
    if (features.monetization) {
      this.subscriptionAlertsService.setRemainSubscriptionDays();
    }
    combineLatest([this.isMouseLeft$, this.dropdownState$]).subscribe((res) => {
      const [mouse, state] = res;

      if (!state && mouse) {
        this.isMouseLeft$.next(false);
        this.setExpandedState(false);
      }
    });
  }

  ngOnInit(): void {
    this.sidenavService.topBarItem$.subscribe((item: NavDrawerItem) => {
      this.initTopBarActions(item);
    });

    this.sidenavService.sidenavState$.subscribe((state: SidenavStateType) => {
      if (state.isCollapsed) {
        this.navDrawer?.closeAll();
      }
    });

    combineLatest([this.accountStorage.hideStart$, this.accountStorage.account]).subscribe(([hideStart]) => {
      const items = NAV_DRAWER_ITEMS(this.features, this.accountStorage, true, hideStart);

      this.navItems$.next(items);
    });
  }

  initTopBarActions(item: NavDrawerItem): void {
    const viewContainerRef = this.actionsHost.viewContainerRef;
    viewContainerRef.clear();

    if (item.actionsComponent) {
      const component: ComponentRef<ActionComponent> = viewContainerRef.createComponent(item.actionsComponent);
      const { actionsComponentData } = item;

      if (actionsComponentData) {
        for (let key in actionsComponentData) {
          const instance: ActionComponent = component.instance;
          instance[key] = actionsComponentData[key];
        }
      }
    }
  }

  setActiveItem(item: NavDrawerItem): void {
    if (this.sidenavService.isNavItemsEqual(item, this.sidenavService.getNavItem())) {
      this.closeMobileSidenav();

      return;
    }

    this.sidenavService.setNavItemConfirmSave(this.bos.getIsMobile() ? { ...item, icon: 'burgerMenu' } : item);

    this.closeMobileSidenav();
  }

  toggleCompactView(event: Event): void {
    event.stopPropagation();
    this.sessionStorage.toggleSidebarView();
    if (this.sessionStorage.isCompactSidebarView()) {
      this.sidenavService.setExpandedState(this.sessionStorage.isCompactSidebarView());
    }

    this.sidenavService.seClickOpenState(true);
  }

  closeSidenav() {
    this.closeMobileSidenav();
  }

  isCompactView(): boolean {
    return this.sessionStorage.isCompactSidebarView();
  }

  clickOnSidenav(): void {
    this.setExpandedState(true);
  }

  setExpandedState(isExpanded: boolean): void {
    if (this.dropdownState$.getValue() === true) {
      this.isMouseLeft$.next(true);
      return;
    }

    this.sidenavService.setExpandedState(isExpanded);
  }

  closeMobileSidenav(): void {
    if (this.bos.getIsMobile() && this.sidenavService.isMobileSidenavOpen()) {
      this.sidenavService.setMobileSidenavOpen(false);
    }
  }
  getDropdownState(event: Boolean) {
    this.dropdownState$.next(event);
  }
  closeTableSidenav() {
    this.tableSidebarService.currentComponent.pipe(take(1)).subscribe((res) => {
      if (res) {
        this.tableSidebarService.confirmExit();
      }
    });
  }

  getOpenSidenav(sidenav: SidenavStateType) {
    return this.sessionStorage.getSidebarView() === 'default' && !sidenav.isMobile;
  }
}
