import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';

import { InputSearchModule } from '@app/shared/component/input-search/input-search.module';

import { IconButtonModule } from '../icon-button/icon-button.module';

import { TopBarComponent } from './top-bar.component';

@NgModule({
  declarations: [TopBarComponent],
  imports: [CommonModule, MatToolbarModule, IconButtonModule, InputSearchModule],
  exports: [TopBarComponent],
})
export class TopBarModule {}
