<div class="nm-input-search" [class.is-top-bar]="isTopBarSearch">
  <div [style.height]="isTopBarSearch ? '40px' : '24px'">
    <nm-svg-icon *ngIf="!isTopBarSearch" [size]="24" [color]="iconsColor" [name]="'search'"></nm-svg-icon>
    <nm-icon-button *ngIf="isTopBarSearch" variant="text" icon="arrowLeft" (clickButton)="setSearch()"></nm-icon-button>
  </div>
  <input #input [attr.data-test-id]="testId" (keyup)="onKeyUp(input.value)" [placeholder]="placeholder" />

  <nm-icon-button
    *ngIf="(value$ | async) ? 'close' : null"
    variant="text"
    [size]="24"
    [icon]="'close'"
    (clickButton)="removeValue()"
  ></nm-icon-button>
</div>
