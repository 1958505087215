<div
  #menuItem
  mat-menu-item
  [ngClass]="menuItemClasses"
  [disabled]="disabled"
  [disableRipple]="true"
  (click)="handleClick()"
  (mouseup)="blur()"
  (mouseleave)="blur()"
  [attr.data-test-option-id]="testId"
>
  <div class="content-container">
    <div class="left" *ngIf="hasLeftSide">
      <div *ngIf="depthLevel !== null && depthLevel !== undefined" class="depth" [ngStyle]="{ marginLeft: leftMargin }">
        <nm-svg-icon
          *ngIf="expandable"
          [size]="24"
          [name]="expanded ? 'keyboardArrowDown' : 'keyboardArrowRight'"
          data-test-id="icon-toggle-expand"
          (click)="handleExpandClick($event)"
          [attr.data-test-option-id]="getExpandIconTestId()"
        ></nm-svg-icon>
      </div>
      <ng-template [ngIf]="checkboxPosition === 'left'">
        <ng-container *ngTemplateOutlet="checkboxTemplate"></ng-container>
      </ng-template>
      <nm-svg-icon *ngIf="iconLeft" [size]="24" [name]="iconLeft"></nm-svg-icon>
      <nm-avatar
        *ngIf="imageUrl || imageTitle"
        [title]="imageTitle"
        [imageUrl]="imageUrl"
        [type]="type"
        (imageLoad)="handleImageLoaded()"
        (imageError)="handleImageLoadError()"
      ></nm-avatar>
      <div *ngIf="imageColor" class="color" [ngStyle]="{ backgroundColor: imageColor }"></div>
      <div class="text" [class.multiline]="multiline">
        <span *ngIf="label" class="label">{{ label }}</span>
        <span *ngIf="description" class="description">{{ description }}</span>
      </div>
    </div>

    <div class="right" *ngIf="hasRightSide">
      <nm-switch *ngIf="isToggle" [isChecked]="toggleActive" (change)="onToggleClick($event, toggleActive)"></nm-switch>
      <span *ngIf="suffix" class="suffix">{{ suffix }}</span>
      <nm-svg-icon
        (click)="handleRightClick($event)"
        *ngIf="iconRight"
        [size]="24"
        [name]="iconRight"
        [color]="iconRightColor"
      ></nm-svg-icon>
      <ng-template [ngIf]="checkboxPosition === 'right'">
        <ng-container *ngTemplateOutlet="checkboxTemplate"></ng-container>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #checkboxTemplate>
  <nm-radio
    *ngIf="showRadioSelect"
    [checked]="selected"
    [disabled]="disabled"
    testId="radio"
    (changeRadio)="handleRadioChange($event)"
    (click)="onCheckboxClick($event)"
  >
  </nm-radio>
  <nm-checkbox
    *ngIf="!showRadioSelect"
    [checked]="selected"
    [disabled]="disabled"
    [indeterminate]="isIndeterminate"
    testId="checkbox"
    (checkboxChange)="handleCheckboxChange($event)"
    (click)="onCheckboxClick($event)"
  ></nm-checkbox>
</ng-template>
