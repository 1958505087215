import { Injectable, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { RevertDialogComponent } from '@app/modules/warehouse/components/revert-dialog/revert-dialog.component';
import { MovingsStorage } from '@app/modules/warehouse/services';
import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { CreateProductDialogComponent } from '@app/shared/component/create-product-dialog/create-product-dialog.component';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { ModalBaseComponent } from '@app/shared/component/dialog/modal-base/modal-base.component';
import { timeFieldValidator } from '@app/shared/component/timepicker/timepicker.component';
import { DateService } from '@app/shared/service/date.service';
import { ExportReportService } from '@app/shared/service/export-report/export-report.service';
import {
  DOC_STATUS,
  exportUrlByReportType,
  MAX_CHARACTERS,
  MAX_FRACTIONAL,
  RootNavigationRoute,
  ROUTE_CREATE_NEW,
  UNIT_TYPE,
  WarehouseRoute,
} from '@constants';
import { FormConfirmSaveService, ValidationErrorsService } from '@core/service';
import { SessionStorage } from '@services/api';
import { CatalogStorage, ProductStorage } from '@services/catalog';
import { NotifyService, SidenavService } from '@services/shared';
import {
  CreateMoveDocInput,
  DateRangeInput,
  DocStatus,
  DocumentNavExtras,
  MoveDoc,
  MoveDocPage,
  MoveProduct,
  MoveProductInput,
  MoveProductsPage,
  MovingForm,
  MovingItemForm,
  PageRequestInput,
  PatchUpdateMoveDocInput,
  ProductCreateInput,
  ProductDialogForm,
  QueryMoveDocsArgs,
  QueryMoveProductsArgs,
  QueryResult,
  StockUnit,
  StockUnitInput,
} from '@typings';
import { getMinValueByFractionalDigitsNumber, notEmpty } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class MovingsService {
  maxFractional = MAX_FRACTIONAL;
  exportReportType = exportUrlByReportType;
  moving: MoveDoc;
  moveProducts: MoveProduct[];
  modalRef: ModalRef<ModalBaseComponent | RevertDialogComponent>;
  form: FormGroup<MovingForm>;

  isEditing$: Observable<boolean>;
  isConfirmed$ = new BehaviorSubject<boolean>(false);
  isSubmitDisabled$ = new BehaviorSubject(false);
  isLoadingConfirmed$ = new BehaviorSubject<boolean>(false);

  productIndex: number | undefined;
  #productDialogRef: ModalRef<unknown>;
  productForm: FormGroup<ProductDialogForm>;
  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private movingsStorage: MovingsStorage,
    private productStorage: ProductStorage,
    private exportReportService: ExportReportService,
    private sessionStorage: SessionStorage,
    private router: Router,
    private fb: FormBuilder,
    private formConfirmSaveService: FormConfirmSaveService,
    private modalService: ModalService,
    private sidenavService: SidenavService,
    private catalogStorage: CatalogStorage,
    private dateService: DateService,
    private notifyService: NotifyService,
    private validationErrorsService: ValidationErrorsService,
  ) {}

  openMovingPage(id?: string, extras?: NavigationExtras): Promise<boolean> {
    this.formConfirmSaveService.closeForm(false);

    return this.router.navigate(
      [this.sessionStorage.getOrgId(), RootNavigationRoute.warehouse, WarehouseRoute.movings, id || ROUTE_CREATE_NEW],
      extras,
    );
  }

  updateMovingForm(id: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const output = this.getMoveWithProducts(id);
      output.then((res) => {
        combineLatest([res[0], res[1]])
          .pipe(take(1))
          .subscribe(([moving, movingProducts]) => {
            this.sidenavService.setTopBarTitle(`Перемещение №${(moving as MoveDoc).number}`);
            this.formConfirmSaveService.closeForm(false);
            this.initForm(moving as MoveDoc, movingProducts as MoveProduct[], undefined);
            resolve();
          });
      });
    });
  }

  back(shouldConfirm: boolean = true) {
    this.sidenavService.back(shouldConfirm);
  }

  showModal(modalTemplate: TemplateRef<ModalBaseComponent>): void {
    this.modalRef = this.modalService.openDialog(modalTemplate);
  }

  initForm(moving: MoveDoc, moveProducts: MoveProduct[], extras?: DocumentNavExtras, shouldConfirm: boolean = true): void {
    this.moving = moving;
    this.moveProducts = moveProducts;

    this.form = this.fb.group<MovingForm>({
      date: this.fb.nonNullable.control(null, [Validators.required]),
      time: this.fb.nonNullable.control(null, [Validators.required, timeFieldValidator()]),
      fromWarehouseId: this.fb.nonNullable.control(null, [Validators.required]),
      toWarehouseId: this.fb.nonNullable.control(null, [Validators.required]),
      documentItems: this.fb.array(new Array<FormGroup<MovingItemForm>>()),
      description: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.DESCRIPTION)]),
      docNumber: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.DOC_NUMBER)]),
    });

    const { controls } = this.form;

    if (this.moving) {
      const { date, number, storageRoomFrom, storageRoomTo, description } = this.moving;
      controls.date.setValue(date || null);
      controls.time.setValue(date ? this.dateService.getTime(new Date(date)) : null);
      controls.fromWarehouseId.setValue(storageRoomFrom?.id && !storageRoomFrom.archived ? storageRoomFrom?.id : null);
      controls.toWarehouseId.setValue(storageRoomTo?.id && !storageRoomTo.archived ? storageRoomTo?.id : null);
      controls.description.setValue(description || null);
      controls.docNumber.setValue(number || number == '0' ? number : null);

      if (this.moveProducts) {
        this.moveProducts.forEach((docItem) => {
          const stockUnit = docItem.product || null;
          const factQuantity = docItem.quantity || null;
          const amountForOne = docItem.quantity || null;

          const documentItemForm = this.createMovingItemFormGroup(stockUnit, Number(factQuantity), amountForOne, false, docItem.sum || '0');
          controls.documentItems.push(documentItemForm);
        });

        if (this.moving.status !== 'CONFIRMED') {
          const nullStockUnit = this.createMovingItemFormGroup();
          controls.documentItems.push(nullStockUnit);
        }
      }

      if (this.moving.status === 'CONFIRMED') {
        this.form.disable();
      }
    } else {
      const now = new Date();
      controls.date.setValue(now.toISOString());
      controls.time.setValue(this.dateService.getTime(now));
    }

    (extras?.items || []).forEach((product) => {
      if (!product) {
        return;
      }

      const stockUnit = {
        id: product.id,
        name: product.name,
        unit: product.unit,
        quantity: product.quantity,
        primePrice: { amountValue: product.primePrice },
      } as StockUnit;
      const quantity = Number(stockUnit.quantity);
      const amountForOne = stockUnit.primePrice?.amountValue || null;

      const documentItemForm = this.createMovingItemFormGroup(stockUnit, quantity, amountForOne, false);

      controls.documentItems.push(documentItemForm);
    });

    if (extras?.items && !!extras?.items.length) {
      const nullStockUnit = this.createMovingItemFormGroup();
      controls.documentItems.push(nullStockUnit);
    }

    this.isEditing$ = new BehaviorSubject(!!this.moving);
    this.isLoadingConfirmed$.next(false);
    this.isConfirmed$.next(this.moving?.status === 'CONFIRMED');
    this.isSubmitDisabled$.next(this.moving?.status === 'CONFIRMED');

    if (shouldConfirm) {
      setTimeout(() => {
        this.formConfirmSaveService.setForm(this.form);
      });
    }
  }

  confirmMoving(): Observable<string | undefined> {
    return this.movingsStorage.confirmMoveDoc({ id: this.moving.id! }).pipe(
      tap(() => {
        this.formConfirmSaveService.closeForm(false);
      }),
      map((res) => (res.data?.confirmMoveDoc.result === 'SUCCESS' ? this.moving.id : '')),
    );
  }

  createMovingItemFormGroup(
    stockUnit: StockUnit | null = null,
    factQuantity: number | null = null,
    amountForOne: string | null = null,
    disabled: boolean = true,
    sum?: string,
  ): FormGroup<MovingItemForm> {
    const stockUnitAutocompleteValue: AutocompleteOption<StockUnit> | null = stockUnit
      ? {
          id: stockUnit.id,
          label: stockUnit.name,
          type: 'item',
          data: stockUnit,
        }
      : null;

    const price = stockUnit?.weightedAveragePrimePrice?.amountValue || stockUnit?.primePrice?.amountValue || '0';
    const amount = this.countAmount(factQuantity || 0, price, stockUnit?.quantity || '') || '0';
    const amountValue = this.isConfirmed() ? sum || amount || '0' : amount || '0';

    return this.fb.group<MovingItemForm>({
      stockUnit: this.fb.nonNullable.control(stockUnitAutocompleteValue),
      factQuantity: this.fb.nonNullable.control({ value: factQuantity || 0, disabled }, [
        Validators.required,
        Validators.min(getMinValueByFractionalDigitsNumber(this.maxFractional.QUANTITY)),
      ]),
      amountForOne: this.fb.nonNullable.control({ value: amountForOne || '0', disabled }, [Validators.required]),
      amount: this.fb.nonNullable.control({ value: amountValue || '0', disabled }, [Validators.required]),
    });
  }

  private getMovingItemsInput(documentItems: FormArray<FormGroup<MovingItemForm>>): MoveProductInput[] {
    return documentItems.controls
      .filter((item) => item.controls.stockUnit.value)
      .map((item) => {
        const { controls } = item;
        const stockUnit = controls.stockUnit.value;

        const input: MoveProductInput = {
          productId: stockUnit?.id || '',
          quantity: String(controls.factQuantity.value) || '0',
          unit: stockUnit?.data?.unit || UNIT_TYPE.NONE,
        };

        return input;
      })
      .filter(notEmpty);
  }

  haveMovingItemsInput(documentItems: FormArray<FormGroup<MovingItemForm>>) {
    return documentItems.length > 1;
  }

  private getMovingCreateInput(): CreateMoveDocInput | null {
    const { toWarehouseId, fromWarehouseId, date, time, description, documentItems, docNumber } = this.form.controls;

    if (!toWarehouseId.value || !fromWarehouseId.value) {
      return null;
    }

    return {
      storageRoomIdTo: toWarehouseId.value,
      storageRoomIdFrom: fromWarehouseId.value,
      date: this.dateService.getDateTime(date.value, time.value) || '',
      description: description.value,
      products: this.getMovingItemsInput(documentItems),
      number: docNumber.value || null,
    };
  }

  private getMovingUpdateInput(): PatchUpdateMoveDocInput | null {
    if (!this.moving || !this.moving.id) {
      return null;
    }

    const { toWarehouseId, fromWarehouseId, date, time, description, documentItems, docNumber } = this.form.controls;

    if (!toWarehouseId.value || !fromWarehouseId.value) {
      return null;
    }

    const productsToAdd: MoveProductInput[] = [];
    const productIdsToRemove: string[] = [];
    const documentProducts = this.getMovingItemsInput(documentItems);

    documentProducts.forEach((d) => {
      if (!this.moveProducts.some((prod) => prod.product?.id === d.productId)) {
        productsToAdd.push(d);
      }
    });

    this.moveProducts.forEach((prod) => {
      if (!documentProducts.some((d) => d.productId === prod.product?.id)) {
        productIdsToRemove.push(prod.id);
      }
    });

    this.moveProducts.forEach((prod) => {
      const docItem = documentProducts.find((d) => d.productId === prod.product?.id)!;
      if (docItem && docItem.quantity !== String(prod.quantity)) {
        productIdsToRemove.push(prod.id);
        productsToAdd.push(docItem);
      }
    });

    return {
      id: this.moving.id,
      storageRoomIdTo: toWarehouseId.value,
      storageRoomIdFrom: fromWarehouseId.value,
      date: this.dateService.getDateTime(date.value, time.value) || '',
      description: description.value,
      productsToAdd,
      productIdsToRemove,
      number: docNumber.value || null,
    };
  }

  async submitForm(): Promise<void> {
    if (!this.haveMovingItemsInput(this.form.controls.documentItems)) {
      this.notifyService.addNotification({
        type: 'alert',
        title: 'При перемещении должны быть указаны позиции',
      });

      return;
    }

    if (this.form.invalid) {
      this.validationErrorsService.markFormControls(this.form);
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо заполнить обязательные поля',
      });

      return;
    }

    this.disableForm();

    if (this.moving) {
      return this.updateMoving(this.getMovingUpdateInput());
    } else {
      this.createMoving(this.getMovingCreateInput());
      return;
    }
  }

  disableForm(): void {
    this.form.disable();
    this.isSubmitDisabled$.next(true);
  }

  enableForm(): void {
    this.form.enable();
    this.form.controls.documentItems.controls.forEach((d) => {
      if (!d.controls.stockUnit.value) {
        d.controls.factQuantity.disable();
      }
    });
    this.isSubmitDisabled$.next(false);
  }

  getProducts(pageRequest: PageRequestInput, searchText: string, excludeStockUnitIds: string[]): QueryResult<'products'> {
    return this.productStorage.getProducts({
      pageRequest,
      filter: {
        search: searchText,
        excludeStockUnitIds,
      },
    });
  }

  allMoveDocPageable(input: QueryMoveDocsArgs): Observable<MoveDocPage> {
    return this.movingsStorage.moveDocs(input).pipe(map((res) => res.data.moveDocs));
  }

  getMoving(id: string): Observable<MoveDoc> {
    return this.movingsStorage.moveDoc({ id }).pipe(map((res) => res.data.moveDoc!));
  }

  createMoving(input: CreateMoveDocInput | null): void {
    if (!input) {
      return;
    }

    this.disableForm();
    this.movingsStorage.createMoveDoc({ input }).subscribe(
      (res) => {
        if (res.data?.createMoveDoc.output?.id) {
          this.openMovingPage(res.data?.createMoveDoc.output?.id);
        }
        this.enableForm();
      },
      () => this.enableForm(),
    );
  }

  async updateMoving(input: PatchUpdateMoveDocInput | null): Promise<void> {
    if (!input) {
      return;
    }
    return new Promise<void>((resolve) => {
      this.movingsStorage.updateMoveDoc({ input }).subscribe((res) => {
        return this.updateMovingForm(res.data?.updateMoveDoc.output?.id || '').then(() => {
          resolve();
          this.enableForm();
        });
      });
    });
  }

  showRevertModal(moving: MoveDoc, callbackFn: () => void): void {
    this.modalRef = this.modalService.openDialog(RevertDialogComponent, {
      data: {
        title: ` перемещение №${moving.number}`,
        callbackFn,
        hideFn: () => {
          this.modalRef.close();
        },
      },
    });
  }

  revertMoving(moving: MoveDoc): Observable<string | undefined> {
    return this.movingsStorage
      .revertMoveDoc({
        id: moving.id,
      })
      .pipe(
        tap(() => {
          this.formConfirmSaveService.closeForm(false);
        }),
        map((_) => moving.id),
      );
  }

  deleteMoving(): void {
    if (!this.moving || !this.moving.id) {
      return;
    }

    this.modalRef.close();
    this.movingsStorage.deleteMoveDoc({ id: this.moving.id }).subscribe(() => {
      this.back(false);
    });
  }

  duplicateMoving(moving: MoveDoc | undefined): void {
    if (!moving || !moving.id || moving.status !== 'CONFIRMED') {
      return;
    }

    this.movingsStorage.duplicateMoving({ documentId: moving.id! }).subscribe((res) => {
      this.openMovingPage(res.data?.duplicateMoveDocument?.output?.id || undefined);
    });
  }

  exportReport(moving: MoveDoc | null | undefined): void {
    if (!moving || !moving.id || !moving.storageRoomFrom || !moving.storageRoomTo) {
      return;
    }

    this.exportReportService.exportReportWithHandler(
      this.exportReportType.MOVE.url,
      {
        warehouseFrom: moving.storageRoomFrom.id,
        warehouseTo: moving.storageRoomTo.id,
        documentId: moving.id,
        zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      this.exportReportType.MOVE.fileName,
    );
  }

  exportReportAll(search: string, warehouseIds: string[], statuses: string[], dateRange: DateRangeInput | null): void {
    const dateFrom = dateRange?.dateFrom ? this.exportReportService.setModifiedDate(dateRange.dateFrom.toString()) : null;
    const dateTo = dateRange?.dateTo ? this.exportReportService.setModifiedDate(dateRange.dateTo.toString()) : null;

    this.exportReportService.exportReportWithHandler(
      this.exportReportType.ALL_MOVE_V2.url,
      {
        search: search || null,
        warehouseIds: warehouseIds.length ? warehouseIds : null,
        statuses: statuses.length ? statuses : null,
        dateFrom,
        dateTo,
        zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      this.exportReportType.ALL_MOVE_V2.fileName,
    );
  }

  hideModal(): void {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.close();
  }

  countAmount(factQuantity: number | null, amountForOne: string | null, quantity: string): string {
    return this.formatNumber(((factQuantity || 0) * Number(amountForOne || 0)) / (Number(quantity) || 1), false);
  }

  formatNumber(num: number, isFixed: boolean = true): string {
    if (isFixed) {
      return num.toFixed(2).replace(/\.0+$/, '');
    } else {
      return String(Math.floor(num * 100) / 100).replace(/\.0+$/, '');
    }
  }

  initProductForm(name: string) {
    this.productForm = this.fb.group<ProductDialogForm>({
      name: this.fb.nonNullable.control(name, [Validators.required, Validators.maxLength(MAX_CHARACTERS.PRODUCT_NAME)]),
      section: this.fb.nonNullable.control(null, [Validators.required]),
      type: this.fb.control(null, [Validators.required]),
      weighable: this.fb.nonNullable.control(false),
      unit: this.fb.nonNullable.control(UNIT_TYPE.PIECE),
      quantity: this.fb.nonNullable.control('1', [Validators.required, Validators.min(0)]),
      primePrice: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
      salePrice: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
      surcharge: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
    });
  }

  openProductDialog(name: string, index: number): void {
    this.initProductForm(name);
    this.productIndex = index;
    this.#productDialogRef = this.modalService.openDialog(CreateProductDialogComponent, {
      data: {
        form: this.productForm,
        title: 'Новая позиция',
        name,
        close: (confirm: boolean) => this.closeCreateProductDialog(confirm),
      },
      disableClose: true,
    });
  }

  closeCreateProductDialog(confirm: boolean): void {
    if (confirm) {
      if (this.productForm.valid) {
        this.createProduct();
        this.hideModalCreateProduct();
      } else {
        this.validationErrorsService.markFormControls(this.productForm);
      }
    } else {
      this.hideModalCreateProduct();
    }
  }

  hideModalCreateProduct(): void {
    if (!this.#productDialogRef) {
      return;
    }
    this.#productDialogRef.close();
  }

  private createProduct(): void {
    const { name, type, weighable, unit, quantity, section, primePrice, salePrice } = this.productForm.controls;

    const stockUnitInput: StockUnitInput = {
      name: name.value,
      weighable: !weighable.value,
      unit: unit.value || 'NONE',
      quantity: quantity.value,
      primePrice: { amountValue: primePrice.value, currencyUnit: this.sessionStorage.getCurrencyUnit() },
      salePrice: { amountValue: salePrice.value, currencyUnit: this.sessionStorage.getCurrencyUnit() },
    };

    const productCreateInput: ProductCreateInput = {
      catalogId: this.catalogStorage.getCatalog().id,
      name: name.value,
      type: type.value,
      sectionId: section.value?.id,
      stockUnits: [stockUnitInput],
    };

    this.productStorage.createProduct({ product: productCreateInput }).subscribe((res) => {
      const stockUnits = res.data?.createProductV2?.output?.stockUnits;

      if (this.productIndex !== undefined && stockUnits) {
        const stockUnit = stockUnits[0];
        this.form.controls.documentItems
          .at(this.productIndex)
          .controls.stockUnit.patchValue({ type: 'item', label: stockUnit.name, id: stockUnit.id, data: stockUnit });
      }
    });
  }

  async getMoveWithProducts(id: string): Promise<Observable<MoveDoc | MoveProduct[]>[]> {
    let allData: MoveProduct[] = [];
    let page = 1;
    let totalPages = 1;
    const filter = {
      docId: id,
    };

    while (page <= totalPages) {
      try {
        let data = await this.getMoveProductsPromise({ filter, pageRequest: { page: page - 1, size: 100 } });
        allData = [...allData, ...data.content];
        if (page === 1) totalPages = data.totalPages;
        page++;
      } catch {
        break;
      }
    }

    return [this.movingsStorage.moveDoc({ id }).pipe(map((res) => res.data.moveDoc!)), of(allData)];
  }

  getMoveProducts(input: QueryMoveProductsArgs): Observable<MoveProductsPage> {
    return this.movingsStorage.moveDocProducts(input).pipe(map((res) => res.data.moveProducts!));
  }

  getMoveProductsPromise(input: QueryMoveProductsArgs): Promise<MoveProductsPage> {
    return new Promise<MoveProductsPage>((resolve) => {
      this.getMoveProducts(input)
        .pipe(take(1))
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  isOpenMove(status: DocStatus) {
    return status === DOC_STATUS.OPEN || status === DOC_STATUS.FAILURE || status === DOC_STATUS.PENDING_CONFIRMATION;
  }

  isConfirmed(): boolean {
    return this.moving?.status === 'CONFIRMED';
  }
}
