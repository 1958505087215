<div class="custom-table" id="custom-table">
  <div
    #tableWrapper
    (scroll)="onScroll($event)"
    class="table-wrapper"
    [attr.data-test.id]="testId"
    [class.table-for-widget-table]="config.isWidgetTable"
  >
    <div class="table" [class.with-selected]="(selectedRows$ | async)?.length">
      <nm-table-header
        #tableHeader
        *ngIf="!config.isHideHeader"
        (selectHeader)="onChangeSelection($event)"
        (headerClick)="onClickHeader($event)"
        (columnDragEnd)="onColumnDragEnd($event)"
        [config]="config"
        [headerConfig]="headerConfig | async"
        [isWidgetTable]="isWidgetTable"
        [rows]="rows"
        [gridTemplateColumns]="tableService.gridTemplateColumns$ | async"
        (mouseleave)="tableService.isResizing.next(false)"
      ></nm-table-header>

      <ng-container *ngIf="config.totalRows?.length">
        <nm-total-rows
          [gridTemplateColumns]="tableService.gridTemplateColumns$ | async"
          [columns]="totalRow?.columnsData"
          [config]="config"
          [row]="totalRow"
          *ngFor="let totalRow of config.totalRows"
        ></nm-total-rows>
      </ng-container>

      <div
        *ngIf="showTopInfiniteLoader()"
        #topSpinner
        nmScrollVisibility
        [threshold]="0.1"
        (visible)="onLoadUpVisible()"
        class="loading"
      >
        <nm-skeleton-template-table [showAlways]="true" [tableConfig]="config"></nm-skeleton-template-table>
      </div>

      <div *ngIf="showTopSpinner()" class="loading">
        <nm-skeleton-template-table [showAlways]="true" [tableConfig]="config"></nm-skeleton-template-table>
      </div>

      <div [ngStyle]="{ height: infiniteLoadingState.offsetTop + 'px' }"></div>

      <div class="empty-title" *ngIf="!infiniteLoadingState.reloading && rows.length === 0">
        {{ config.emptyTitle }}
      </div>

      <nm-table-body
        #tableBody
        (expandRow)="onRowExpand($event)"
        (enumerationUpdate)="onEnumerationUpdate()"
        (selectRow)="onRowSelect($event)"
        (dragStart)="onDragStart($event)"
        (dragEnd)="onDragEnd()"
        (dropRow)="onDropRow($event)"
        (dragRow)="onDragRow($event)"
        (rowHidden)="onRowHidden($event)"
        (rowVisible)="onRowVisible($event)"
        (rowHeightInited)="onRowHeightInited($event)"
        [tableElement]="elRef"
        [columnsDataTemplates]="columnsDataTemplates"
        [gridTemplateColumns]="tableService.gridTemplateColumns$ | async"
        [config]="config"
        [rows]="viewRows$ | async"
        [isDragging]="isDragging"
        [isMouseOver]="tableService.isMouseOver | async"
        [isMouseOverIndex]="tableService.isMouseOverIndex | async"
      >
        <ng-content></ng-content>
      </nm-table-body>

      <div [ngStyle]="{ height: infiniteLoadingState.offsetBottom + 'px' }"></div>

      <div
        *ngIf="!infiniteLoadingState.reloading && infiniteLoadingState.canLoad"
        nmScrollVisibility
        #bottomSpinner
        [threshold]="0.1"
        (visible)="onLoadMore()"
        class="loading"
      >
        <nm-skeleton-template-table [showAlways]="true" [tableConfig]="config"></nm-skeleton-template-table>
      </div>
    </div>
  </div>
</div>
