import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { CoreSchema } from '@typings';

import { NotifyService } from './notify/notify.service';
import { TimezonesApi } from './timezones.api';

@Injectable({
  providedIn: 'root',
})
export class TimezonesService {
  constructor(private timezonesApi: TimezonesApi, private notifyService: NotifyService) {}

  getTimezones(): Observable<CoreSchema.TimeZone[]> {
    return this.timezonesApi.getTimezones().pipe(
      map((res) => res.data.timezones.timeZones),
      this.notifyService.$notify({
        error: 'Ошибка при получении списка таймзон',
      }),
    );
  }
}
