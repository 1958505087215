<div class="actions">
  <ng-container *ngIf="isTablet | async">
    <nm-button
      *ngIf="settings?.buttonAction"
      data-test-id="topbar::right-action-button"
      [variant]="settings.buttonAction.variant || 'filled'"
      (click)="settings?.buttonAction.onClick()"
      >{{ settings.buttonAction.label }}</nm-button
    >
  </ng-container>

  <nm-icon-button *ngIf="isSearch" variant="text" icon="search" (clickButton)="setSearch()"></nm-icon-button>

  <nm-icon-button
    *ngIf="(items$ | async)?.length"
    [disabled]="isMenuDisabled$ | async"
    variant="text"
    icon="moreVert"
    [matMenuTriggerFor]="(isMenuDisabled$ | async) ? null : menu.childMenu"
    data-test-id="topbar::mobile-dots-button"
  ></nm-icon-button>

  <nm-menu #menu class="menu" [selectOnItemClick]="false" [items]="items$ | async"></nm-menu>
</div>
