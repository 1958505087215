import { CURRENCIES, UNIT_TYPE_STRING_SHORT } from '@constants';
import { FormatCurrencyConfig, UnitType } from '@typings';

export const formatCurrency = (amount: number | string, config: FormatCurrencyConfig): string => {
  const { unit, toFixedNumber } = config;

  return (
    Number(amount)
      .toFixed(toFixedNumber)
      .replace(/\B(?=(\d{3})+(?!\d))/g, '$& ') +
    ' ' +
    unit
  );
};

export function getUnitWithSpace(unit: UnitType | null | undefined): string {
  if (!unit) {
    return '';
  }

  return ' ' + UNIT_TYPE_STRING_SHORT[unit];
}

export function getCurrencyWithSpace(currencyUnit: string | null | undefined): string {
  if (!currencyUnit || !CURRENCIES[currencyUnit]) {
    return '';
  }

  return ' ' + CURRENCIES[currencyUnit];
}
