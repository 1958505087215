import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatMenuItem } from '@angular/material/menu';
import { MatRadioChange } from '@angular/material/radio';
import { SafeUrl } from '@angular/platform-browser';

import { SVG_ICONS_TYPE } from '@constants';

@Component({
  selector: 'nm-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
  @Input() label: string = '';
  @Input() description: string = '';
  @Input() suffix: string = '';
  @Input() disabled: boolean = false;
  @Input() selected: boolean = false;
  @Input() stopCheckboxClick: boolean = true;
  @Input() stopRightIconClick: boolean = true;
  @Input() iconLeft: SVG_ICONS_TYPE;
  @Input() iconRight: SVG_ICONS_TYPE;
  @Input() iconRightColor: string = '';
  @Input() imageUrl: string | SafeUrl = '';
  @Input() type: 'round' | 'square' = 'square';
  @Input() imageColor: string = '';
  @Input() allowMultiselect: boolean = false;
  @Input() isIndeterminate: boolean = false;
  @Input() showRadioSelect: boolean = false;
  @Input() checkboxPosition: 'left' | 'right' | 'none' = 'none';
  @Input() selectOnClick: boolean = true;
  @Input() depthLevel: number | null = null;
  @Input() expandable: boolean = false;
  @Input() expanded: boolean = false;
  @Input() multiline: boolean = false;
  @Input() imageTitle = '';
  @Input() testId: string = '';
  @Input() toggleActive: boolean = false;
  @Input() isToggle: boolean = false;

  @Output() selectedChange = new EventEmitter<boolean>();
  @Output() expandClick = new EventEmitter<boolean>();
  @Output() checkboxClick = new EventEmitter<boolean>();
  @Output() toggleClick = new EventEmitter<boolean>();
  @Output() imageLoad = new EventEmitter<void>();
  @Output() imageError = new EventEmitter<void>();
  @Output() checkboxChange = new EventEmitter<MatCheckboxChange>();
  @Output() rightIconClick = new EventEmitter<boolean>();

  @ViewChild('menuItem') menuItem: MatMenuItem;

  get menuItemClasses(): string[] {
    const classes = ['nm-menu-item'];

    if (this.selected && this.checkboxPosition === 'none') {
      classes.push('nm-menu-item-selected');
    }

    if (this.description) {
      classes.push('nm-menu-item-description');
    }

    return classes;
  }

  get hasLeftSide() {
    return this.label || this.iconLeft || this.checkboxPosition === 'left' || (this.depthLevel !== null && this.depthLevel !== undefined);
  }

  get hasRightSide() {
    return this.suffix || this.iconRight || this.checkboxPosition === 'right' || this.isToggle;
  }

  get leftMargin(): string {
    return `${(this.depthLevel ?? 0) * 8}px`;
  }

  handleClick(): void {
    if (this.selectOnClick && !this.disabled) {
      this.selectedChange.emit(!this.selected && !this.isIndeterminate);
    }
  }

  handleRightClick(event: Event) {
    this.rightIconClick.emit(!this.selected && !this.isIndeterminate);

    if (this.stopRightIconClick) {
      event.stopPropagation();
    }
  }

  handleExpandClick(e: MouseEvent) {
    this.expandClick.emit(!this.expanded);

    e.stopPropagation();
  }

  onCheckboxClick(event: Event) {
    this.checkboxClick.emit(!this.selected && !this.isIndeterminate);

    if (this.stopCheckboxClick) {
      event.stopPropagation();
    }
  }

  onToggleClick(event: Event, active: boolean) {
    this.toggleClick.emit(!active);
    event.stopPropagation();
  }

  handleCheckboxChange(event: MatCheckboxChange): void {
    this.selectedChange.emit(event.checked);
    this.checkboxChange.emit(event);
  }

  handleRadioChange(event: MatRadioChange) {
    this.selectedChange.emit(event.source.checked);
  }

  handleImageLoaded() {
    this.imageLoad.emit();
  }

  handleImageLoadError() {
    this.imageError.emit();
  }

  blur() {
    const activeElement = this.menuItem['_document'].activeElement;
    const menuItemElement = this.menuItem['_elementRef'].nativeElement;

    if (activeElement === menuItemElement) {
      menuItemElement.blur();
    }
  }

  getExpandIconTestId(): string {
    return `icon-${this.testId}` + this.expanded ? 'keyboardArrowDown' : 'keyboardArrowRight';
  }
}
