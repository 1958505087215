<mat-toolbar [attr.data-test-id]="testId" [ngClass]="componentClasses">
  <ng-container *ngIf="openSearch">
    <nm-input-search
      placeholder="Поиск"
      [isTopBarSearch]="true"
      [initialValue]="initialSearch"
      [lettersThreshold]="searchCharsCount"
      (searchKeyup)="onSearch($event)"
    ></nm-input-search>
  </ng-container>

  <ng-container *ngIf="!openSearch">
    <nm-icon-button
      testId="topbar::topbar-icon"
      *ngIf="icon"
      variant="text"
      [icon]="icon"
      (click)="onBtnClick()"
    ></nm-icon-button>
    <nm-icon-button
      testId="topbar::topbar-icon"
      *ngIf="iconLeft"
      variant="text"
      [icon]="iconLeft"
      (click)="onLeftClick()"
    ></nm-icon-button>
    <div class="top-bar-title">
      <span class="title-content">{{ title }}</span>
      <span *ngIf="count" class="title-count">{{ count }}</span>
    </div>
    <ng-content></ng-content>
    <ng-content select="[class=controls]"></ng-content>
  </ng-container>
</mat-toolbar>
