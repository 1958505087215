import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { FilterTreeNodeData } from '@app/shared/component/filter-tree/filter-tree.component';
import { TreeDataService, TreeNode, TreeNodeFetchResult } from '@app/shared/service/tree-data/tree-data.service';
import { rootMenuSection } from '@constants/menu-section';
import { SectionRc } from '@typings';

import { MenuStorage } from './menu/menu.storage';

export type CatalogTreeServiceOptions = {
  excludedSectionIds: string[];
  isRootSection: boolean;
  preloadAllNodes: boolean;
  categories: boolean;
  selectedNodeIds$?: Observable<string[]>;
};

const defaultOptions: CatalogTreeServiceOptions = {
  excludedSectionIds: [],
  isRootSection: false,
  preloadAllNodes: false,
  categories: false,
  selectedNodeIds$: undefined,
};

@Injectable({
  providedIn: 'root',
})
export class MenuSectionTreeService {
  cachedParentSectionIds = new Set<string>();
  constructor(private menuStorage: MenuStorage) {}

  initSections(options: Partial<CatalogTreeServiceOptions> = {}): TreeDataService<AutocompleteOption> {
    const mapper = (section: SectionRc) => {
      const { id, name } = section as SectionRc;

      const option: TreeNodeFetchResult<AutocompleteOption> = {
        id: id,
        isParent: false,
        data: {
          id: id,
          type: 'item',
          label: name,
          data: section,
        },
      };

      option.isParent = section.rightMargin - section.leftMargin > 1;
      return option;
    };

    return this.#initSections(mapper, options);
  }

  initFilterTreeSections(options: Partial<CatalogTreeServiceOptions> = {}): TreeDataService<FilterTreeNodeData> {
    const mapper = (catalogResponse: SectionRc) => {
      const { id, name } = catalogResponse;

      const option: TreeNodeFetchResult<FilterTreeNodeData> = {
        id,
        isParent: false,
        data: {
          name,
        },
      };

      option.isParent = catalogResponse.rightMargin - catalogResponse.leftMargin > 1;

      return option;
    };

    return this.#initSections(mapper, options);
  }

  #initSections<T>(
    mapper: (section: SectionRc) => TreeNodeFetchResult<T>,
    options: Partial<CatalogTreeServiceOptions> = {},
  ): TreeDataService<T> {
    const mergedOptions: CatalogTreeServiceOptions = {
      ...defaultOptions,
      ...options,
    };

    const sectionsTreeService = new TreeDataService<T>();

    sectionsTreeService.fetchChildren = (parentSectionId, maxDepth): Observable<TreeNodeFetchResult<T>[]> => {
      let fetchedCatalogResponses$: Observable<SectionRc[]>;
      fetchedCatalogResponses$ = this.menuStorage
        .getMenuSectionsShort({
          filter: {
            menuIds: [this.menuStorage.menu?.id || ''],
            types: ['SECTION'],
            depthLevel: [maxDepth],
            parentSectionIds: parentSectionId ? [parentSectionId] : null,
          },
          pageable: {
            page: 0,
            size: 1000,
          },
        })
        .pipe(
          map((res) => {
            return res.data.menuRcElements.elements as SectionRc[];
          }),
        );

      return fetchedCatalogResponses$.pipe(
        map((catalogResponses) => {
          if (!parentSectionId && mergedOptions.isRootSection) {
            catalogResponses = [rootMenuSection, ...catalogResponses];
          }

          return catalogResponses
            .filter((catalogResponse) => {
              const sectionNotInList = !mergedOptions.excludedSectionIds.includes(catalogResponse.id);

              if (parentSectionId) {
                return sectionNotInList;
              }

              if (!parentSectionId && 'isParent' in catalogResponse) {
                return sectionNotInList && catalogResponse.depthLevel === 0;
              }

              return sectionNotInList;
            })
            .map(mapper);
        }),
      );
    };
    sectionsTreeService.fromLoadedNodes = true;
    sectionsTreeService.filterNodeFromStorage = (node: TreeNode<T>, text) => {
      const nodeName = (node.data as AutocompleteOption<SectionRc>).data?.name.toLowerCase() || '';
      const searchText = text.toLowerCase();
      return !!nodeName.includes(searchText);
    };

    let loadedPromise = options.preloadAllNodes ? sectionsTreeService.loadAllNodes() : sectionsTreeService.loadRootNodes();

    loadedPromise.then(() => {
      if (mergedOptions.selectedNodeIds$) {
        mergedOptions.selectedNodeIds$.subscribe((selectedIds) => {
          sectionsTreeService.setSelectedNodeIds(selectedIds);
        });
      }
    });

    return sectionsTreeService;
  }
}
