<div class="filter-label" (click)="filterClick.emit($event)" [attr.data-test-id]="testId">
  <div class="filter-label-left-side">
    <div *ngIf="iconLeft">
      <nm-svg-icon [size]="18" class="icon" [name]="iconLeft"></nm-svg-icon>
    </div>
    <div class="label-text"><ng-content></ng-content></div>
  </div>
  <div class="right-icon">
    <nm-svg-icon [size]="18" class="icon" [name]="isOpened ? 'arrowDropUp' : 'arrowDropDown'"></nm-svg-icon>
  </div>
</div>
