import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PARENT_SCROLL_WRAPPER_ATTR } from '../table/helper';

@Component({
  selector: 'nm-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListingComponent {
  @Input() isHeader: boolean = true;
  @Input() isFooter: boolean = true;
  @Input() isEmpty: boolean = false;
  @Input() isSelect: boolean = false;
  @Input() isOnlyTable: boolean = true;

  readonly scrollWrapperAttr = PARENT_SCROLL_WRAPPER_ATTR;
}
