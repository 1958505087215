import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

export enum LOG_LEVEL {
  none = 'none',
  info = 'info',
  warning = 'warning',
  debug = 'debug',
  error = 'error',
}

export type LogLevelType = keyof typeof LOG_LEVEL;

@Injectable({
  providedIn: 'root',
})
export class LoggerService implements OnDestroy {
  private readonly logLevelParam = 'logLevel';

  #destroy: Subject<void> = new Subject<void>();

  constructor(route: ActivatedRoute) {
    route.queryParams.pipe(takeUntil(this.#destroy)).subscribe((params) => {
      const logLevel: LogLevelType = params[this.logLevelParam];
      if (logLevel) {
        this.setLogLevel(logLevel);
      }
    });
  }

  canLog(level: LogLevelType): boolean {
    const currentLevel = localStorage.getItem(this.logLevelParam);

    if (currentLevel === LOG_LEVEL.error) {
      return level === LOG_LEVEL.error;
    } else if (currentLevel === LOG_LEVEL.warning) {
      return level === LOG_LEVEL.error || level === LOG_LEVEL.warning;
    } else if (currentLevel === LOG_LEVEL.info) {
      return level === LOG_LEVEL.error || level === LOG_LEVEL.warning || level === LOG_LEVEL.info;
    } else return currentLevel === LOG_LEVEL.debug;
  }

  log(message: string, level: LogLevelType) {
    if (this.canLog(level)) {
      switch (level) {
        case LOG_LEVEL.error:
          console.error(message);
          break;
        case LOG_LEVEL.warning:
          console.warn(message);
          break;
        case LOG_LEVEL.info:
          console.info(message);
          break;
        default:
          // eslint-disable-next-line no-console
          console.debug(message);
      }
    }
  }

  logWarning(message: string): void {
    this.log(message, 'warning');
  }

  logError(message: string): void {
    this.log(message, 'error');
  }

  logInfo(message: string): void {
    this.log(message, 'info');
  }

  logDebugInfo(message: string): void {
    this.log(message, 'debug');
  }

  setLogLevel(level: LogLevelType): void {
    localStorage.setItem(this.logLevelParam, level);
  }

  clear(): void {
    localStorage.removeItem(this.logLevelParam);
  }

  ngOnDestroy(): void {
    this.#destroy.next();
    this.#destroy.complete();
    this.clear();
  }
}
